<template>
	<div class="general-modal-bg" v-if="this.isMenuOpen === true" @click="onBgClicked">
		<div class="main-menu-container">
			<div class="main-menu-item" @click="onMenuItemClicked('health_check')">检测</div>
			<div class="main-menu-item" @click="onMenuItemClicked('ticket')">工单</div>
			<div class="main-menu-item" @click="onMenuItemClicked('statistic')">统计</div>
		</div>
	</div>
	<div class="main-arrow-container" v-if="this.isMenuOpen === false" @click="onMenuArrowClicked">
		<img src="../images/arrow_right_menu.svg" alt="arrow right" style="width: 2em;">
	</div>
</template>
<script>

import {hideLoading, postRequestAsync, showLoading} from "@/utils/methods";
import moment from "moment-timezone";

export default {
	name: "MainMenu",
	data() {
		return {}
	},
	props: {
		isMenuOpen: {
			type: Boolean,
			required: true
		}
	},
	async mounted() {
	},
	methods: {
		onMenuItemClicked(param) {

			if (param === 'health_check') {
				this.$router.push('/dashboard/hc')
			} else if (param === 'ticket') {
				this.$router.push('/dashboard/cs')
			} else if (param === 'statistic') {
				this.$router.push('/dashboard/stats')
			}
			this.$emit('menuItemClicked');
		},
		onMenuArrowClicked() {
			this.$emit('showMenu');
		},
		onBgClicked() {
			this.$emit('hideMenu');
		}
	}
}
</script>
<style scoped>
.main-menu-container {
	position: fixed;
	height: 100%;
	width: 40%;
	top: 0;
	left: 0;
	background-color: #191123;
	box-sizing: border-box;
	padding: 0.8em 0.2em;
}
.main-menu-item{
	box-sizing: border-box;
	padding: 0.2em;
	cursor: pointer;
	color: #E6D5B8;
	font-size: 1.2em;
	border-bottom: 1px solid #E6D5B8;
}
.main-arrow-container{
	position: fixed;
	background-color: rgba(25, 17, 35, 0.8);
	width: fit-content;
	left: 0;
	height: 4em;
	display: flex;
	justify-content: center;
	align-items: center;
	border-top-right-radius: 0.4em;
	border-bottom-right-radius: 0.4em;
	top: 50%;
	transform: translateY(-50%);
	z-index: 10;
	border-right: 1px solid rgba(230, 213, 184, 0.8);
	border-top: 1px solid rgba(230, 213, 184, 0.8);
	border-bottom: 1px solid rgba(230, 213, 184, 0.8);
}
</style>