<template>
	<div class="health-check-container">
		Statistic Page
	</div>
</template>

<script>

export default {
	name: "StatisticPage",
	components: {

	},
	data() {
		return {
		}
	},
	async mounted() {
	},
	methods: {

	}
}
</script>

<style scoped>
.health-check-container {

}

</style>